import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { ROUTE_DASHBOARD } from 'features/dashboard/routes';

const Index = () => {
  const { push } = useRouter();

  useEffect(() => {
    push(ROUTE_DASHBOARD);
  }, [push]);

  return null;
};

export default Index;
